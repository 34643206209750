
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.feedback {
  &__actions {
    display: flex;
    flex-direction: row;
    &-item {
      cursor: pointer;
      margin: 0 0 0 10px;
      &:first-child {
        margin: 0;
      }
    }
  }
}
.form-item {
  margin: 20px 0 0 0;
  &:first-child {
    margin: 0;
  }
}
.feedback {
  .esmp-table__thead-tr {
    .esmp-table__thead-th:first-child {
      width: 42px;
    }
    .esmp-table__thead-th:last-child {
      width: 83px;
    }
  }
}
